import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/300-italic.css"
import "@fontsource/raleway/400.css"
import "@fontsource/raleway/500.css"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/800.css"
// import "@fontsource/raleway/cyrillic-300.css"
// import "@fontsource/raleway/cyrillic-300-italic.css"
// import "@fontsource/raleway/cyrillic-400.css"
// import "@fontsource/raleway/cyrillic-500.css"
// import "@fontsource/raleway/cyrillic-700.css"
// import "@fontsource/raleway/cyrillic-800.css"

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';

import Header from "./header"
import Footer from "./footer"
import { ModalProvider } from "./modal/modal-context"
import Modal from "./modal/modal"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00b1fd',
      secondary: '#7b78f2',
    },
    secondary: {
      main: '#7b78f2',
    },
    text: {
      primary: '#414141',
    },
    background: {
      default: '#fff',
    },
    gradientDark: {
      color1: '#313545',
      color2: '#121118',
      main: 'linear-gradient(277.09deg, #313545 15.4%, #121118 100.07%)',
      reverse: 'linear-gradient(277.09deg, #121118 15.4%, #313545 100.07%)',
      main60: 'linear-gradient(277.09deg, rgba(49, 53, 69, .6) 15.4%, rgba(18, 17, 24, .6) 100.07%)',
      main95: 'linear-gradient(277.09deg, rgba(49, 53, 69, .95) 15.4%, rgba(18, 17, 24, .95) 100.07%)',
    },
    gradientBlue: {
      color1: '#9358f7',
      color2: '#7b78f2',
      color3: '#6197ee',
      color4: '#45b5e9',
      color5: '#10d7e2',
      main: 'linear-gradient(51.06deg, #9358f7 0.87%, #7b78f2 25.96%, #6197ee 49.23%, #45b5e9 74.93%, #10d7e2 97.48%)',
    },
    gradientWhite: {
      color1: '#ffffff',
      color2: '#c9c9c9',
      main: 'linear-gradient(180deg, #ffffff 0%, #c9c9c9 100%)',
    },
  },
  typography: {
    fontFamily: '"Raleway", "Arial", sans-serif',
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1600,
    }
  },

  overrides: {
    MuiButton: {
      containedPrimary: {
        background: 'linear-gradient(51.06deg, #9358f7 0.87%, #7b78f2 25.96%, #6197ee 49.23%, #45b5e9 74.93%, #10d7e2 97.48%)',
        color: '#fff',
      },
      sizeLarge: {
        padding: '12px 40px',
        fontSize: '1.1rem',
      },
    },
  }
})

theme.typography.h1 = {
  fontSize: '1.8rem',
  fontWeight: 800,
  lineHeight: 1.1,
  '@media (min-width:375px)': {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.5rem',
  },
}

theme.typography.h2 = {
  fontSize: '1.7rem',
  fontWeight: 700,
  lineHeight: 1.2,
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
}

theme.typography.body1 = {
  fontSize: '1.5rem',
  lineHeight: 1.4,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
}

theme.typography.subtitle1 = {
  fontSize: '1.2rem',
  lineHeight: 1.2,
  fontWeight: 400,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
}

const Layout = ({ fixedHeader, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <ModalProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} fixedHeader={fixedHeader} />
          <div>
            <main>{children}</main>
          </div>
          <Footer />
          <Modal />
        </ThemeProvider>
      </ModalProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
