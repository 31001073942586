import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { useModal } from './modal-context';
import DialogForm from '../forms/dialog-form';

const useStyles = makeStyles((theme) => ({

  wrapper: {
    position: 'relative',
  },

  closeDialog: {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
    padding: 0,
  },

  closeButton: {
    position: 'relative',
    width: '40px',
    height: '40px',
    background: [theme.palette.secondary.main],
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
    transition: 'background .2s',

    '&:hover': {
      background: [theme.palette.text.primary],
    },

    '& span': {
      position: 'absolute',
      width: '30px',
      height: '2px',
      transformOrigin: '50% 50%',
      top: '19px',
      left: '5px',
      background: '#fff',
    },

    '& > :first-child': {
      transform: 'rotate(-45deg)',
    },
    '& > :last-child': {
      transform: 'rotate(45deg)',
    },
  },

  dialogTitle: {
    color: [theme.palette.secondary.main],
    width: '80%',
    '& > *': {
      lineHeight: 1.2,
    },
  },

  contentText: {
    marginBottom: '2rem',
    color: [theme.palette.text.primary],
  },
}))

const Modal = () => {

  const classes = useStyles()

  const {open, hide, title, description, purpose, fields} = useModal()

  return (
    <Dialog open={open} onClose={hide} aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {description}
        </DialogContentText>
        <DialogForm fields={fields} purpose={purpose} />
      </DialogContent>
      <DialogActions className={classes.closeDialog}>
        <div className={classes.closeButton} onClick={hide} role="button" tabIndex={0} aria-hidden="true">
          <span></span>
          <span></span>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default Modal
