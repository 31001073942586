import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, TextField } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

const endpoints = {
  contact: "/.netlify/functions/send-email",
}

const useStyles = makeStyles(theme => ({
  buttonBox: {
    textAlign: "center",
  },

  policy: {
    fontSize: ".7rem",
    lineHeight: 1.1,
    display: "block",
    marginBottom: "1rem",

    "& a": {
      color: "inherit",
    },
  },

  errorBox: {

    display: 'flex',
    flexDirection: 'column',

    '& p': {
      color: '#d40202',
      fontWeight: 500,
      padding: '5px',
      backgroundColor: [theme.palette.grey[200]],
      border: '1px solid #dadada',
      marginTop: '5px',
      borderRadius: '5px',

      '&::before': {
        display: 'inline',
        content: '"⚠ "',
      },
    },
  },

  formMessage: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
    
    '& p': {
      padding: '5px 10px',
      color: 'white',
      fontWeight: 500,
      borderRadius: '5px',
    },
  },

  errorMessage: {
    '& p': {
      backgroundColor: '#ff1717',
    },
  },
  successMessage: {
    '& p': {
      backgroundColor: '#2cb52a',
    },
  },

  [theme.breakpoints.up("md")]: {
    buttonBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "right",

      "& > *": {
        flex: "0 0 49%",
      },
    },
    policy: {
      marginBottom: "0",
      paddingRight: ".5rem",
    },
  },
}))

const DialogForm = ({ purpose, fields }) => {
  const classes = useStyles()

  /**
   * Form submit
   */

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  })

  const [formState, setFormState] = useState({
    successForm: false,
    errorForm: false,
  })

  const [submittedData, setSubmittedData] = useState({})

  const onSubmit = async (data, e) => {
    
    setSubmittedData(data)

    try {
      const response = await fetch(endpoints.contact, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        //not 200 response

        reset({ ...submittedData })

        setFormState({
          successForm: false,
          errorForm: true,
        })
        return
      }

      //all OK
      reset({ ...submittedData })

      setFormState({
        successForm: true,
        errorForm: false,
      })
    } catch (e) {
      //error
      console.error("Произошла Ошибка: ", e)
    }
  }

  /**
   * End Form submit
   */

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      name="Форма_футер"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      noValidate
    >
      <input type="hidden" name="form-name" value="Модальная_форма" />
      <input type="hidden" {...register("Цель формы")} value={purpose} />
      {fields.name ? (
        <Box mb={2}>
          <TextField
            {...register("Имя", {
              required: "Напишите ваше имя",
            })}
            className={classes.textField}
            label="Ваше имя"
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
          />
          <Box className={classes.errorBox} mt={1}>
            <ErrorMessage
              errors={errors}
              name="Имя"
              render={({ messages }) => {
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                      <p key={type}>{message}</p>
                    ))
                  : null
              }}
            />
          </Box>
        </Box>
      ) : null}
      {fields.phone ? (
        <Box mb={2}>
          <TextField
            {...register("Телефон", {
              required: "Оставьте свой номер телефона",
              pattern: {
                value: /\d+/,
                message: "Можно использовать только цифры.",
              },
              minLength: {
                value: 11,
                message: "Должно быть не менее 11 цифр.",
              },
            })}
            label="Номер телефона"
            type="tel"
            variant="outlined"
            color="secondary"
            required
            fullWidth
            size="small"
          />
          <ErrorMessage
            errors={errors}
            name="Телефон"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p key={type}>{message}</p>
                  ))
                : null
            }}
          />
        </Box>
      ) : null}
      {fields.mail ? (
        <Box mb={2}>
          <TextField
            {...register("Почта", {
              required: "Напишите вашу почту",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Некорректный адрес почты"
              }
            })}
            label="Ваша почта"
            type="email"
            variant="outlined"
            color="secondary"
            required
            fullWidth
            size="small"
          />
          <Box className={classes.errorBox} mt={1}>
            <ErrorMessage
              errors={errors}
              name="Почта"
              render={({ messages }) => {
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                      <p key={type}>{message}</p>
                    ))
                  : null
              }}
            />
          </Box>
        </Box>
      ) : null}
      {fields.comment ? (
        <Box mb={2}>
          <TextField
            {...register("Комментарий")}
            label="Кратко опишите проект..."
            variant="outlined"
            color="secondary"
            required
            size="small"
            fullWidth
            multiline={true}
            rows="4"
          />
        </Box>
      ) : null}
      <Box className={classes.buttonBox}>
        <span className={classes.policy}>
          Нажимая на кнопку, вы даете согласие на обработку персональных данных
          и соглашаетесь с{" "}
          <a href="/policy" target="_blank" rel="noopener noreferrer">
            политикой конфиденциальности
          </a>
          .
        </span>
        <Button type="submit" color="primary" variant="contained" size="large">
          Оставить заявку
        </Button>
      </Box>
      { formState.successForm && <Box className={`${classes.successMessage} ${classes.formMessage}`}><p>Форма успешно отправлена.</p></Box> }
      { formState.errorForm && <Box className={`${classes.errorMessage} ${classes.formMessage}`}><p>Произошла ошибка. Попробуйте еще раз.</p></Box> }
    </form>
  )
}

export default DialogForm
