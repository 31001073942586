import React, {useState, useEffect} from "react"
import { makeStyles } from "@material-ui/styles"
import { Link } from 'gatsby';
import { Box, Container } from "@material-ui/core"

import {LogoGradient} from '../svg-js/svg-images'

import PhoneIcon from '../svg/phone-icon.svg'
import MailIcon from '../svg/mail-icon.svg'
import Facebook from '../svg/facebook.svg'
import Instagram from '../svg/instagram.svg'
import VkIcon from '../svg/vk.svg'
import Behance from '../svg/behance.svg'

const useStyles = makeStyles((theme) => ({

  footer: {
    color: '#fff',
    background: [theme.palette.gradientDark.reverse],
  },

  footerBot: {
    position: 'relative',
    padding: '10px 0',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: [theme.palette.text.primary],
      mixBlendMode: 'multiply',
      zIndex: 1,
      opacity: .6,
    },
  },

  botContent: {
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',
    padding: '5px 0',
    '& p': {
      fontSize: '14px',
      fontWeight: 500,
    }
  },

  gridBox: {
    display: 'grid',
    gridTemplateAreas: `'about contacts'
                        'links links'`,
    gridGap: '15px 30px',
    gridTemplateColumns: '1fr 1fr',
  },

  gridItem1: {
    gridArea: 'about'
  },
  gridItem2: {
    gridArea: 'links',
    borderTop: '1px solid #fff',
    paddingTop: '15px',
  },
  gridItem3: {
    gridArea: 'contacts'
  },

  logoBox: {
    marginBottom: '20px',
  },

  aboutText: {
    fontSize: '13px',
    lineHeight: 1.1,

    '& span': {
      fontWeight: 800,
      textTransform: 'uppercase',
    },
  },

  columnTitleBox: {
    marginBottom: '30px',
  },

  title: {
    fontSize: '1.3rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: 1,
  },

  contactsBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',

    '& svg': {
      width: '20px',
      height: 'auto',
    },

    '& a': {
      paddingLeft: '10px',
      color: '#fff',
      textDecoration: 'none',
      fontWeight: 500,
      display: 'block',
      lineHeight: 1,
      transition: 'color .2s linear',
      fontSize: '.65rem',

      '&:hover': {
        color: [theme.palette.primary.main]
      },
    },
  },

  linksList: {
    columnCount: '2',

    '& li': {
      marginBottom: '5px',
    },

    '& a': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#fff',
      transition: 'color .2s linear',
      '&:hover': {
        color: [theme.palette.primary.main],
        textDecoration: 'none',
      },
    }
  },

  socialIconBox: {
    paddingRight: '8px',

    '& a': {
      display: 'block',
      cursor: 'pointer',
    },
    '& svg': {
      display: 'block',
      width: '20px',
    }
  },

  '@media (min-width: 400px)': {
    contactsBox: {
  
      '& svg': {
        width: 'auto',
        height: 'auto',
      },
  
      '& a': {
        fontSize: '.875rem',
      },
    },
  },

  [theme.breakpoints.up('md')]: {

    gridBox: {
      gridTemplateAreas: `"about links contacts"`,
      gridTemplateColumns: '3fr 4fr 3fr',
      justifyContent: 'space-between',
      gridGap: '50px',
    },

    gridItem2: {
      border: 'none',
      paddingTop: '0',
    },

    logoBox: {
      marginBottom: '35px',
    },

    aboutText: {
      fontSize: '1.2rem',
    },

    title: {
      fontSize: '1.9rem',
    },

    linksList: {
      '& li': {
        fontSize: '20px',
      },
    },

    contactsBox: {

      marginBottom: '20px',

      '& a': {
        fontSize: '30px',
        paddingLeft: '20px',
      },
    },

    socialIconBox: {

      paddingRight: '20px',

      '& svg': {
        width: '30px',
      },
    },

    footerBot: {

    },

    botContent: {
      textAlign: 'left',
    },

  },

  '@media only screen and (min-device-width : 768px) and (max-device-width : 1170px) and (orientation : landscape)': {
    contactsBox: {

      '& a': {
        fontSize: '20px',
        paddingLeft: '20px',
      },
    },
  },
}))

const Footer = () => {

  const classes = useStyles()

  const [logoWidth, setLogoWidth] = useState(150)

  const onWindowResize = () => {
    let docWidth = document.body.clientWidth

    if (docWidth > 960) {
      setLogoWidth(230)
    } else {
      setLogoWidth(150)
    }
  }

  useEffect(() => {
    onWindowResize()
    window.addEventListener('resize', onWindowResize)
    
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  })


  const links = [
    {
      url: '/services',
      name: 'Услуги',
    },
    {
      url: '/services/web-dev',
      name: 'Разработка сайтов',
    },
    {
      url: '/services/design',
      name: 'Дизайн',
    },
    {
      url: '/services/marketing',
      name: 'Реклама',
    },
    {
      url: '/portfolio',
      name: 'Портфолио',
    },
    {
      url: '/about',
      name: 'Контакты',
    },
    {
      url: '/about',
      name: 'О нас',
    },
    {
      url: '/policy',
      name: 'Политика конфиденциальности',
    },
  ]

  return (
    <footer className={classes.footer} id="footer">
      <Box className={classes.footerTop} pt={4} pb={2}>
        <Container maxWidth="xl">
          <Box className={classes.gridBox}>
            <div className={classes.gridItem1}>
              <div className={classes.logoBox}>
                <LogoGradient width={logoWidth}/>
              </div>
              <p className={classes.aboutText}><span>ACSENT</span> — Воплощаем ваши мечты в интернет-пространстве</p>
            </div>
            <div className={classes.gridItem2}>
              <div className={classes.columnTitleBox}>
                <p className={classes.title}>быстрые ссылки</p>
              </div>
              <nav className={classes.linksBox}>
                <ul className={classes.linksList}>
                  {
                    links.map((link, idx) => (
                      <li className={classes.linkItem} key={`${link.name}-${idx*3}`}>
                        <Link to={link.url}>{link.name}</Link>
                      </li>
                    ))
                  }
                </ul>
              </nav>
            </div>
            <div className={classes.gridItem3}>
              <div className={classes.columnTitleBox}>
                <p className={classes.title}>Контакты</p>
              </div>
              <div className={classes.contactsBox}>
                <PhoneIcon />
                <a href="tel:+79998430073">+7 (999) 843-00-73</a>
              </div>
              <div className={classes.contactsBox}>
                <MailIcon />
                <a href="mailto:hello@ascent.ru">hello@ascentweb.ru</a>
              </div>
              <Box className={classes.socialBox} mt={{xs: 2, md: 6}} display="flex" alignItems="center" justifyContent="flex-start">
                <Box className={classes.socialIconBox}>
                  <a href="#!" target="_blank" rel="noopener noreferrer">
                    <Facebook />
                  </a>
                </Box>
                <Box className={classes.socialIconBox}>
                  <a href="#!" target="_blank" rel="noopener noreferrer">
                    <Instagram />
                  </a>
                </Box>
                <Box className={classes.socialIconBox}>
                  <a href="#!" target="_blank" rel="noopener noreferrer">
                    <VkIcon />
                  </a>
                </Box>
                <Box className={classes.socialIconBox}>
                  <a href="#!" target="_blank" rel="noopener noreferrer">
                    <Behance />
                  </a>
                </Box>
              </Box>
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={classes.footerBot}>
        <Container maxWidth="xl">
          <div className={classes.botContent}>
            <p>©{new Date().getFullYear()}. Веб-студия «ASCENT»</p>
          </div>
        </Container>
      </Box>
    </footer>
  )
}


export default Footer
