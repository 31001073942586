import React, { createContext, useContext, useReducer } from 'react'

const ModalContext = createContext()

export const useModal = () => {
  return useContext(ModalContext)
}

const SHOW_MODAL = 'show'
const HIDE_MODAL = 'hide'

const reducer = (state, action) => {
  switch (action.type) {
    case SHOW_MODAL: return {...state, open: true, title: action.title, description: action.description, purpose: action.purpose, fields: action.fields}
    case HIDE_MODAL: return {...state, open: false}
    default: return state
  }
}

export const ModalProvider = ({ children }) => {


  const [state, dispatch] = useReducer(reducer, {
    open: false,
    title: null,
    description: null,
    purpose: 'Консультация',
    fields: {
      name: true,
      phone: true,
      mail: true
    }
  })

  const show = (title, description, purpose, fields) => dispatch(({ type: SHOW_MODAL, title, description, purpose, fields }))
  const hide = () => dispatch(({ type: HIDE_MODAL }))

  return (
    <ModalContext.Provider
      value={{
        open: state.open,
        show,
        hide,
        title: state.title,
        description: state.description,
        purpose: state.purpose,
        fields: state.fields,
      }}
    >
      { children }
    </ModalContext.Provider>
  )
}
