import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, Container, Grid, Hidden } from "@material-ui/core"
import { Logo } from "../svg-js/svg-images"

import Facebook from '../svg/facebook.svg'
import Instagram from '../svg/instagram.svg'
import VkIcon from '../svg/vk.svg'
import Behance from '../svg/behance.svg'
import { useModal } from './modal/modal-context';

import Chevronne from '../svg/down-arrow.svg'


const useStyles = makeStyles((theme) => ({

  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: 199,

    '&.active': {
      background: [theme.palette.gradientDark.main],
    },

    '&.position-relative': {
      position: 'relative',
      background: [theme.palette.gradientDark.main],
    },
  },

  wrapper: {
    color: 'white',
  },

  menuWrap: {
    display: "flex",
    flexDirection: 'column',
  },

  logoBox: {
    '& a, & svg': {
      display: 'block',
    }
  },

  menu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // borderBottom: '1px solid #fff',
    marginBottom: [theme.spacing(6)],
    position: 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '-1.5rem',
      left: 0,
      width: '50%',
      height: '1px',
      background: '#fff',
    },
  },

  menuItem: {
    '&:not(:last-child)': {
      marginBottom: [theme.spacing(3)],
    },
    position: 'relative',
  },

  menuLink: {
    color: '#fff',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: '20px',
    padding: '10px 0px',
    position: 'relative',
    willChange: 'transform',
    fontWeight: 600,

    '&.with-submenu': {
      
      display: 'flex',
      alignItems: 'center',

      '& > svg': {
        display: 'inline-block',
        height: '15px',
        width: 'auto',
        marginLeft: '10px',
        fill: '#00b1fd',
      },

      '&.active': {
        '& > svg': {
          transform: 'rotate(180deg)',
        },
      },
    },

    '&.accent': {
      border: '2px solid #00b1fd',
      fontWeight: 700,
      padding: '10px 15px',
    },

    '&.active + .submenuBox': {
      display: 'block',
    },
  },

  /**
   * Submenu styles
   */

   submenuBox: {
    display:'none',
    padding: '1.5rem',

    '& li a': {
      textTransform: 'none',
      fontWeight: 500,

      '&:hover:before': {
        content: 'none',
      },
    },
  },

  submenuList: {
    '& li a': {
      padding: 0,
      opacity: .95,
      transition: 'opacity .2s',

      '&:hover': {
        opacity: 1,
      },
    },
  },

  /** Mobile Menu */

  mobMenu: {
    overflowY: 'auto',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: [theme.palette.gradientDark.main95],
    padding: [theme.spacing(2)],
    zIndex: 200,

    display: 'grid',
    gridTemplateRows: '50px 1fr 130px',
    gridTemplateColumns: '1fr',
    justifyContent: 'space-between',
    gridGap: '40px',
    transform: 'translateX(-105%)',
    transition: 'transform .3s ease-out',

    '&.active': {
      transform: 'translateX(0px)',
    },
  },

  closeMenu: {
    width: '40px',
    height: '30px',
    position: 'relative',
    cursor: 'pointer',

    '& span': {
      width: '100%',
      height: '4px',
      backgroundColor: '#fff',
      borderRadius: '5px',
      position: 'absolute',
      transformOrigin: 'center',
      left: 0,
      top: 'calc(50% - 2px)',
    },

    '& span:first-child': {
      transform: 'rotate(45deg)',
    },
    '& span:last-child': {
      transform: 'rotate(-45deg)',
    },
  },

  socialIconBox: {
    padding: '0 10px',
  },

  /** Burger */

  burgerTrigger: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '40px',
    height: '27px',
    cursor: 'pointer',

    '& span': {
      width: '100%',
      height: '4px',
      backgroundColor: '#fff',
      borderRadius: '5px',
    }
  },


  [theme.breakpoints.up('lg')]: {

    menuWrap: {
      justifyContent: "flex-end",
      flexDirection: 'row',
    },

    menu: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 0,
      borderBottom: 'none',

      '&:before': {
        content: 'none',
      },
    },

    navBox: {
      display: 'flex',
      alignItems: 'center',
    },

    menuItem: {
      marginRight: [theme.spacing(2)],
      '&:not(:last-child)': {
        marginBottom: 0,
      },
      position: 'relative',
    },
  
    menuLink: {
      fontSize: '20px',
      padding: '10px 15px',
      position: 'relative',
      willChange: 'transform',
  
      '&:before': {
        // background: 'none repeat scroll 0 0 transparent',
        bottom: 0,
        content: '""',
        display: 'block',
        height: '2px',
        left: '50%',
        position: 'absolute',
        background: [theme.palette.gradientBlue.main],
        transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
        width: 0,
      },
      '&:hover:before': {
        width: '100%',
        left: 0,
      },
  
      '&:hover': {
        // fontWeight: 600
      },
    },

    submenuBox: {
      padding: '1.5rem',
      position: 'absolute',
      minWidth: '550px',
      transform: 'translateY(100%)',
      bottom: '-10px',
      background: 'rgba(49, 53, 69, 0.95)',
      borderTop: '3px solid #00b1fd',
      borderBottom: '6px solid #7b78f2',
      boxShadow: '0px 0px 4px 0px rgba(255, 255, 255, 0.2) inset',
    },

    submenuList: {
      columnCount: 2,
      columnGap: '1rem',

      '& li a': {
        padding: 0,
      },
      '& li:not(:last-child)': {
        marginBottom: '1rem',
      },
      '& a': {
        fontWeight: 500,
      },
    },
  }
}))

/**
 * Hook that toggles submenu
 */
 function useOutsideSubmenu(ref, stateFunc) {
  useEffect(() => {
    /**
     * Clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        stateFunc(false)
        document.querySelector('.with-submenu').classList.remove('active')
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, stateFunc]);
}

const Header = ({fixedHeader}) => {

  const classes = useStyles()

  const [logoWidth, setLogoWidth] = useState(150)

  const [mobMenu, setMobMenu] = useState(false)

  const [activeSubMenu, setActiveSubMenu] = useState(false)

  const header = useRef()

  const mobileMenuBox = useRef()

  const submenuBox = useRef()

  const toggleMenu = () => {
    mobileMenuBox.current.classList.toggle('active')
    setMobMenu(!mobMenu)
  }

  const toggleSubMenu = (e) => {
    if (!activeSubMenu) {
      e.preventDefault()
      setActiveSubMenu(true)
      e.target.classList.add('active')
    } else {
      e.preventDefault()
      setActiveSubMenu(false)
      e.target.classList.remove('active')
    }
  }

  const onWindowResize = () => {
    let docWidth = document.body.clientWidth

    if (docWidth > 960) {
      setLogoWidth(192)
    } else {
      setLogoWidth(150)
    }
  }

  const onScrollHeader = () => {
    let currentScroll = window.pageYOffset

    if (currentScroll > 1) {
      header.current.classList.add('active')
    } else {
      header.current.classList.remove('active')
    }
  }

  useOutsideSubmenu(submenuBox, setActiveSubMenu)


  useEffect(() => {
    onWindowResize()
    if (!fixedHeader) {
      header.current.classList.add('position-relative')
    } else {
      window.addEventListener('scroll', onScrollHeader)
    }

    window.addEventListener('resize', onWindowResize)

    if (mobMenu) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }
    
    return () => {
      window.removeEventListener('resize', onWindowResize)
      fixedHeader && window.removeEventListener('scroll', onScrollHeader)
    }
  }, [fixedHeader, mobMenu])


  return (
    <header ref={header} className={classes.header} id="header">
      <Box className={classes.wrapper} py={3}>
        <Container maxWidth="xl">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            spacing={3}
          >
            <Grid item md={2}>
              <Box className={classes.logoBox}>
                <Link to="/">
                  <Logo width={logoWidth} />
                </Link>
              </Box>
            </Grid>
            <Hidden mdDown>
              <Grid item md={10}>
                <Menu toggleSubMenu={toggleSubMenu} submenuBox={submenuBox} />
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid item>
                <Box className={classes.burgerTrigger} onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
          <Box ref={mobileMenuBox} className={classes.mobMenu}>
            <Box className={classes.mobMenuHeader} display="flex" justifyContent="space-between">
              <Logo width={192} />
              <Box className={classes.closeMenu} onClick={toggleMenu}>
                <span></span>
                <span></span>
              </Box>
            </Box>
            <Box className={classes.mobMenuBody}>
              <Menu toggleMenu={toggleMenu} toggleSubMenu={toggleSubMenu} />
            </Box>
            <Box className={classes.mobMenuFooter} display="flex" alignItems="center" justifyContent="center">
              <Box className={classes.socialIconBox}>
                <Link to="#!">
                  <Facebook />
                </Link>
              </Box>
              <Box className={classes.socialIconBox}>
                <Link to="#!">
                  <Instagram />
                </Link>
              </Box>
              <Box className={classes.socialIconBox}>
                <Link to="#!">
                  <VkIcon />
                </Link>
              </Box>
              <Box className={classes.socialIconBox}>
                <Link to="#!">
                  <Behance />
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </header>
  )
}

export default Header

const Menu = ({toggleMenu, toggleSubMenu, submenuBox}) => {

  const classes = useStyles()

  const {show} = useModal()

  const modalTitle = 'Обратный звонок'
  const modalDesc = 'Заполните форму заявки, и наш менеджер свяжется с вами в ближайшее время 🙂'
  const modalFields = {
    name: true,
    phone: true,
    mail: false,
    comment: false,
  }

  const menu = [
    {name: 'Главная', link: '/', key: 'link-main'},
    {name: 'Услуги', link: '/services', key: 'link-services',
      submenu: [
        {name: 'Все услуги', link: '/services', key: 'submenu-link-web-dev', accent: true},
        {name: 'Разработка сайтов', link: '/services/web-dev', key: 'submenu-link-web-dev'},
        {name: 'Landing Page', link: '/services/web-dev/landing', key: 'submenu-link-landing'},
        {name: 'Небольшой сайт', link: '/services/web-dev/small-site', key: 'submenu-link-small-site'},
        {name: 'Корпоративный сайт', link: '/services/web-dev/corp-site', key: 'submenu-link-corp-site'},
        {name: 'Сайт-квиз', link: '/services/web-dev/quiz-site', key: 'submenu-link-quiz-site'},
        {name: 'Интернет-магазин', link: '/services/web-dev/internet-shop', key: 'submenu-link-internet-shop'},
        {name: 'Контекстная реклама', link: '/services/marketing', key: 'submenu-link-marketing'},
        {name: 'Дизайн', link: '/services/design', key: 'submenu-link-design'},
      ]
    },
    {name: 'Портфолио', link: '/portfolio', key: 'link-portfolio'},
    {name: 'Контакты', link: '/about', key: 'link-contacts'},
  ]

  return (
    <Box className={classes.menuWrap}>
      <nav className={classes.navBox} role="navigation" aria-label="Навигация">
        <ul className={classes.menu}>
          {
            menu.map((item, idx) => {

              if (item.submenu) {
                return (
                  <li className={classes.menuItem} key={`${item.key}-${idx}`} >
                    <Link to={item.link} className={`${classes.menuLink} with-submenu`} onClick={(e) => toggleSubMenu(e)} onMouseOver={(e) => toggleSubMenu(e)}>
                      {item.name}
                      <Chevronne />
                    </Link>
                    <Box ref={submenuBox} className={`${classes.submenuBox} submenuBox`}>
                      <ul className={classes.submenuList}>
                        {
                          item.submenu.map((item, idx) => {

                            if (item.accent) {
                              return (
                                <li className={classes.menuItem} key={`${item.key}-${idx}`} >
                                  <Link to={item.link} className={`${classes.menuLink} accent`} onClick={toggleMenu || null}>{item.name}</Link>
                                </li>
                              )
                            } else {
                              return (
                                <li className={classes.menuItem} key={`${item.key}-${idx}`} >
                                  <Link to={item.link} className={classes.menuLink} onClick={toggleMenu || null}>{item.name}</Link>
                                </li>
                              )
                            }
                          })
                        }
                      </ul>
                    </Box>
                  </li>
                )
              } else {
                
                return (
                  <li className={classes.menuItem} key={`${item.key}-${idx}`} >
                    <Link to={item.link} className={classes.menuLink} onClick={toggleMenu || null}>{item.name}</Link>
                  </li>
                )
              }

            })
          }
        </ul>
      </nav>
      <Box>
        <Button color="primary" variant="contained" size="large" onClick={() => show(modalTitle, modalDesc, modalTitle, modalFields)}>Связаться</Button>
      </Box>
    </Box>
  )
}
